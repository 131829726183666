@use "variable" as var;
@use "sass:math";

.main {
  padding: 35px 0 50px;

  @media #{var.$md} {
    padding-top: 15px;
  }
}

.wrap {
  width: min(100%, 960px);
  margin: 0 auto;
}

.blockText {
  display: inline-block;
}

.archive-link {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.button {
  width: min(100%, 175px);
  padding: 11px 8px 11px 15px;
  border: 1px solid var.$main-color;
  background-color: var.$main-color;
  font-size: 14px;
  line-height: 1.35;
  color: var.$white;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
  transition: background-color 0.3s, color 0.3s;

  &::after {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    background-image: url("../img/button-arrow-right.svg");
    background-size: contain;
    background-repeat: no-repeat;
    flex-shrink: 0;
    transition: background-color 0.3s;
  }

  @media (hover: hover) {
    &:hover {
      background-color: var.$white;
      color: var.$main-color;

      &::after {
        background-image: url("../img/button-arrow-right-active.svg");
      }
    }
  }
}

.section-title {
  background-color: var.$main-color;
  color: var.$white;
  background-image: url("../img/title-backimage.png");
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: right;
  background-blend-mode: multiply;
  min-height: 80px;
  font-size: 40px;
  line-height: 1;
  letter-spacing: 0.15em;
  font-family: var.$didact-gothic;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 10px;
  padding: 10px 10px 10px 30px;

  @media #{var.$md} {
    background-image: url("../img/title-backimage-small.png");
  }

  &--row {
    flex-direction: row;
    align-items: baseline;
  }

  &--pick-up {
    font-size: 37px;
    background-image: url("../img/title-backimage-pick-up.png");
    min-height: 58px;
    padding: 10px;
    justify-content: stretch;
  }

  &--faculty-graduate {
    @media #{var.$md} {
      font-size: 30px;
    }
  }

  &::after {
    content: attr(data-sub-title);
    display: inline-block;
    font-size: 14px;
    letter-spacing: 0.08em;
    font-family: var.$hiragino-sans;
  }

  &--news {
    &::after {
      @media #{var.$md} {
        font-size: 12px;
      }
    }
  }
}

.section {
  margin-top: 80px;

  @media #{var.$md} {
    margin-top: 50px;
  }

  &.topics {
    margin-top: 40px;
  }
}

.first-view {
  display: grid;
  grid-template-columns: 1fr 358px;
  gap: 40px 25px;

  @media #{var.$lg} {
    grid-template-columns: 100%;
    padding: 0 10px;
    row-gap: 30px;
  }
}

//メインビジュアルスライダー
.image-slider {
  grid-column: 1 / 2;
  overflow: hidden;
  width: 100%;

  @media #{var.$lg} {
    display: none;
  }
}

.image-slider__slider {
  &.slick-dotted.slick-slider {
    margin-bottom: 0;
  }

  .slick-dots {
    bottom: 11px;

    li {
      width: 14px;
      height: 14px;
      margin: 0 8px;
      transition: opacity 0.3s;

      button {
        width: 100%;
        height: 100%;
        padding: 0;

        &::before {
          content: "";
          background-color: var.$main-color;
          border: 1px solid var.$main-color;
          border-radius: 100%;
          width: 100%;
          height: 100%;
          opacity: 1;
        }
      }

      &.slick-active {
        pointer-events: none;

        button::before {
          background-color: var.$white;
        }
      }

      @media (hover: hover) {
        &:hover {
          opacity: 0.65;
        }
      }
    }
  }
}

.image-slider__image-box {
  mix-blend-mode: plus-lighter;
}

.image-slider__image {
  width: 100%;
  padding-top: calc(math.div(354, 577) * 100%);
  background-position: top left;
  background-size: cover;
  background-repeat: no-repeat;
}

//ピックアップ
.pick-up {
  grid-column: -1 / -2;
  width: 100%;
  box-shadow: 0 3px 6px rgba(#000000, 0.16);

  @media #{var.$lg} {
    grid-column: 1;
  }
}

.pick-up__container {
  overflow: hidden;
}

.pick-up__box {
  padding: 20px 24px 14px;
}

.pick-up__slider {
  &.slick-dotted.slick-slider {
    margin-bottom: 35px;
  }

  .slick-dots {
    bottom: -35px;

    li {
      width: 10px;
      height: 10px;
      margin: 0 6px;
      transition: opacity 0.3s;

      button {
        width: 100%;
        height: 100%;
        padding: 0;

        &::before {
          content: "";
          background-color: var.$main-color;
          border: 1px solid var.$main-color;
          border-radius: 100%;
          width: 100%;
          height: 100%;
          opacity: 1;
        }
      }

      &.slick-active {
        pointer-events: none;

        button::before {
          background-color: var.$white;
        }
      }

      @media (hover: hover) {
        &:hover {
          opacity: 0.65;
        }
      }
    }
  }

  .slick-arrow {
    width: 24px;
    height: 24px;
    @include var.z-index(default-plus);

    &::before {
      content: "";
      display: inline-block;
      width: 100%;
      height: 100%;
      opacity: 1;
      transition: background-image 0.3s;
    }

    &.slick-prev {
      left: 5px;
      transform: translate(-50%, -50%);

      &::before {
        content: "";
        background-image: url("../img/slider-arrow-left.svg");
        background-size: contain;
        background-repeat: no-repeat;
      }

      @media (hover: hover) {
        &:hover {
          &::before {
            background-image: url("../img/slider-arrow-left-action.svg");
          }
        }
      }
    }

    &.slick-next {
      right: 5px;
      transform: translate(50%, -50%);

      &::before {
        content: "";
        background-image: url("../img/slider-arrow-right.svg");
        background-size: contain;
        background-repeat: no-repeat;
      }

      @media (hover: hover) {
        &:hover {
          &::before {
            background-image: url("../img/slider-arrow-right-action.svg");
          }
        }
      }
    }
  }
}

.pick-up__link {
  display: block;
  margin: 0 5px;
  color: inherit;
  text-decoration: none;
  transition: opacity 0.3s;

  @media (hover: hover) {
    &:hover {
      opacity: 0.65;
    }
  }
}

.pick-up__thumbnail {
  width: 100%;
  padding-top: calc(math.div(180, 300) * 100%);
  background-position: top left;
  background-size: cover;
  background-repeat: no-repeat;
}

.pick-up__posted-date {
  font-size: 12px;
  line-height: 1.33;
  display: block;
  margin-top: 8px;
}

.pick-up__article-title {
  font-size: 14px;
  line-height: 1.35;
  letter-spacing: 0.08em;
  color: var.$main-color;
  font-weight: 700;
  margin-top: 4px;
}

//お知らせリスト
.news-list {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  row-gap: 25px;
  list-style: none;
  margin-top: 30px;
  position: relative;

  @media #{var.$md} {
    grid-template-columns: 100%;
    row-gap: 18px;
  }

  &::before {
    content: "";
    display: inline-block;
    width: 1px;
    height: 100%;
    background-color: #d5d5d5;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    @media #{var.$md} {
      display: none;
    }
  }
}

.news-list__item {
  padding-left: 20px;
  padding-right: 48px;

  @media #{var.$md} {
    padding-left: 10px;
    padding-right: 10px;
  }

  &:nth-of-type(2) {
    grid-column: 1;
    grid-row: 2;

    @media #{var.$md} {
      grid-row: auto;
    }
  }

  &:nth-of-type(3) {
    grid-column: 2;
    grid-row: 1;

    @media #{var.$md} {
      grid-column: 1;
      grid-row: auto;
    }
  }
}

.news-list__link {
  display: grid;
  grid-template-columns: 130px 1fr;
  column-gap: 12px;
  color: inherit;
  text-decoration: none;

  @media (hover: hover) {
    &:hover {
      .news-list__thumbnail {
        opacity: 0.64;
      }

      .news-list__title {
        color: var.$main-color;
        text-decoration: underline;
      }
    }
  }
}

.news-list__thumbnail {
  width: 100%;
  padding-top: calc(math.div(81, 130) * 100%);
  background-position: top left;
  background-size: cover;
  background-repeat: no-repeat;
  align-self: flex-start;
  transition: opacity 0.3s;
}

.news-list__posted-date {
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 0.08em;
  display: block;
}

.news-list__title {
  font-size: 14px;
  line-height: 1.57;
  letter-spacing: 0.08em;
  margin-top: 7px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  transition: color 0.3s, text-decoration 0.3s;
}

//ニュース
.news-category {
  margin-top: 30px;
  padding: 0 10px;
}

.news-category__list {
  display: flex;
  justify-content: center;
  list-style: none;
}

.news-category__item {
  width: min(100%, 150px);
  padding: 0 15px;
  border-left: 1px solid var.$main-color;

  @media #{var.$md} {
    padding: 0 5px;
  }

  &:last-of-type {
    border-right: 1px solid var.$main-color;
  }
}

.news-category__button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  width: 100%;
  height: 100%;
  min-height: 40px;
  padding: 0;
  background-color: transparent;
  font-size: 14px;
  line-height: 1.35;
  letter-spacing: 0.08em;
  color: var.$main-color;
  cursor: pointer;
  transition: opacity 0.3s;

  @media #{var.$md} {
    font-size: 10px;
    line-height: 1.5;
  }

  &--selected {
    background-color: var.$main-color;
    color: var.$white;
    pointer-events: none;
  }

  @media (hover: hover) {
    &:hover {
      opacity: 0.65;
    }
  }
}

.news-archive {
  margin-top: 25px;
  list-style: none;
  display: none;

  @media #{var.$lg} {
    padding: 0 10px;
  }

  &--active {
    display: block;
  }
}

.news-archive__item {
  border-top: 2px dotted #c3c3c3;

  &:last-of-type {
    border-bottom: 2px dotted #c3c3c3;
  }
}

.news-archive__link {
  display: flex;
  column-gap: 76px;
  color: inherit;
  text-decoration: none;
  padding: 18px 45px 18px 21px;
  font-size: 14px;
  line-height: 1.35;

  @media #{var.$md} {
    flex-direction: column;
    row-gap: 8px;
    padding: 14px 5px;
  }

  @media (hover: hover) {
    &:hover {
      .news-archive__title {
        color: #6c57c6;
      }
    }
  }
}

.news-archive__posted-date {
  flex-shrink: 0;
}

.news-archive__title {
  color: #0055f5;
  text-decoration: underline;
  font-size: inherit;
  font-weight: 400;
  letter-spacing: 0.08em;
  transition: color 0.3s;
}

//トップ緊急お知らせ
.attention {
  @media #{var.$lg} {
    padding: 0 10px;
  }
}

.attention__link {
  display: block;
  width: 100%;
  border: 4px solid #01b051;
  padding: 42px 20px;
  font-size: 24px;
  line-height: 1.3;
  letter-spacing: 0.1em;
  font-weight: 700;
  color: inherit;
  text-align: center;
  text-decoration: none;
  transition: opacity 0.3s;

  @media #{var.$md} {
    font-size: 16px;
    padding: 20px 20px 32px;
  }

  @media (hover: hover) {
    &:hover {
      opacity: 0.6;
    }
  }
}

//学科紹介
.faculty-graduate-department {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  color: var.$white;
  margin-top: 30px;

  @media #{var.$md} {
    grid-template-columns: 1fr;
    margin-top: 35px;
  }
}

.faculty-graduate-department__link {
  color: inherit;
  overflow: hidden;
  text-decoration: none;
  position: relative;

  @media (hover: hover) {
    &:hover {
      .faculty-graduate-department__thumbnail {
        transform: scale(1.3);
      }
    }
  }
}

.faculty-graduate-department__thumbnail {
  width: 100%;
  height: auto;
  transition: transform 0.5s;
}

.faculty-graduate-department__name {
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 106px;
  padding: 0 25px 5px;
  background-image: linear-gradient(rgba(var.$main-color, 0), var.$main-color);
  font-size: 24px;
  line-height: 1.25;
  letter-spacing: 0.15em;
}

.faculty-graduate-other {
  padding: 50px 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  background-color: #e9f2f8;
  color: var.$white;
}

.faculty-graduate-other__link {
  position: relative;
  text-decoration: none;
  color: inherit;

  @media (hover: hover) {
    &:hover {
      .faculty-graduate-other__title {
        background-color: transparent;
      }
    }
  }
}

.faculty-graduate-other__thumbnail {
  width: min(100%, 300px);
  height: auto;
}

.faculty-graduate-other__title {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(var.$main-color, 0.61);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  line-height: 1.33;
  letter-spacing: 0.15em;
  font-weight: 400;
  transition: background-color 0.5s;

  &--entrance-examination {
    background-color: rgba(#000000, 0.61);
    font-size: 17px;
  }
}

//バナー
.banner {
  margin-top: 80px;
  display: flex;
  justify-content: space-between;
  gap: 20px;

  @media #{var.$lg} {
    padding: 0 20px;
  }

  @media #{var.$md} {
    flex-direction: column;
    align-items: center;
  }
}

.banner__link {
  display: block;
  transition: opacity 0.3s;

  @media (hover: hover) {
    &:hover {
      opacity: 0.64;
    }
  }
}
